  //选择签章人弹出框
<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    title="新增签章人"
    class="addSginer"
  >
    <base-form
      :componentList="signerFrom"
      :showBtns="false"
      :formAttrs="{
        model: signerQuery,
      }"
      class="formStyle"
      ref="signerData"
    >
    </base-form>
    <template slot="footer">
      <base-button label="提交" @click="submitSigner" />
      <base-button label="取消" @click="visibleState = false" />
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form'
// import { personnelApi } from '@/api/companyApi'
import { signerConfig } from '../utils/add-authentication-config'
import { contactApi } from '@/api/contactApi'
import { certifyApi } from '@/api/companyApi'

export default {
  props: {
    visible: Boolean,
    enterpriseId: String
  },
  data () {
    return {
      title: '新增签章人',
      signerQuery: {},
      personnelList: []
    }
  },
  components: { BaseDialog, BaseButton, BaseForm },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
        if (!val) {
          this.$nextTick(() => {
            this.$refs.signerData.clearValidate()
          })
        }
      }
    },
    signerFrom () {
      return signerConfig(this)
    }

  },
  watch: {
    visible (val) {
      if (val) {
        this.getGysPersonnelList()
        this.$set(this.signerQuery, 'isDefault', '1')
      } else {
        this.signerQuery = {}
      }
    },
    enterpriseId (val) {
      if (val) {
        this.getGysPersonnelList()
      }
    }
  },

  methods: {
    // 签证人下拉
    getGysPersonnelList () {
      contactApi.getGysPersonnelList({ enterpriseId: this.enterpriseId }).then(res => {
        if (res.success) {
          this.personnelList = res.data
        }
      })
    },
    // 选择人员后回显
    changeSigner (data) {
      const [obj] = this.personnelList.filter(item => item.keyId === data)
      if (obj) {
        this.$set(this.signerQuery, 'idCard', obj.idCard)
        this.$set(this.signerQuery, 'phone', obj.mobilePhoneNo)
        this.$set(this.signerQuery, 'email', obj.email)
      } else {
        this.$set(this.signerQuery, 'idCard', '')
        this.$set(this.signerQuery, 'phone', '')
        this.$set(this.signerQuery, 'email', '')
      }
    },

    // 提交
    submitSigner () {
      this.$refs.signerData.validate(valid => {
        if (valid) {
          const data = {
            enterpriseId: this.enterpriseId,
            enableStatus: 1,
            ...this.signerQuery,
            sealAdminType: '1'
          }
          console.log(data)
          certifyApi.addOrgSealAdmin(data).then(res => {
            if (res.data) {
              this.success('添加成功')
              this.$parent.handleFilter()
              this.visibleState = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.addSginer {
  margin-top: 200px;
  height: 500px;
}

.tips {
  padding: 16px;
  font-size: 16px;
  color: #ee1c1c;
}
</style>
