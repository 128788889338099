//供应商证书管理
<template>
  <div class="certificateManagement">
    <!--表单-->
    <base-form
      :componentList="form"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      ref="fromdata"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    >
      <!-- 隐藏查询选项按钮 -->
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div>
    </base-form>
    <!-- 供应商 有的 -->
    <div class="addBtn" v-if="role === '22'">
      <base-button label="安心签平台办理业务授权书" @click="handleBusiness" ></base-button>
      <base-button
        :label="showBtn"
        @click="addEnterprise"
        :disabled="authStatus === '2'"
      ></base-button>
      <base-button label="新增个人认证" @click="addPersonal" ></base-button>
    </div>
    <!--    表格-->
    <base-table
      :columns="columns"
      :showPage="false"
      :tableAttrs="{
        data: tableData,
        stripe: false,
        spanMethod: objectSpanMethod,
        border: true,
      }"
      ref="tableData"
      :webPage="true"
      class="main-page-table"
      :class="{'with-add': role === '22'}"
    >
      <!-- 页面新增签章人按钮 -->
      <template slot="sealAdminType" slot-scope="scope">
        <div
          class="addSigner"
          v-if="scope.row.sealAdminType === '1' && role === '22'&&scope.row.authStatus==='2'"
        >
          <div class="addBox">
            <el-tooltip   effect="dark"  :enterable="false" content="添加签章人" placement="top" >
              <i class="el-icon-plus" @click="selectVisible = true"></i>
            </el-tooltip>
          </div>
        </div>
        {{ scope.row.sealAdminType === "1" ? "企业认证" : "个人认证" }}
      </template>
      <!-- 设置默认签章人 -->
      <template slot="personnerName" slot-scope="scope">
        <div v-if="scope.row.sealAdminType === '1' && role === '22'" class="setIsDefault" :class="{activeDefault:scope.row.isDefault==='1'}">
          <!-- <el-tooltip effect="dark"  :enterable="false" content="设置默认签章人" placement="top" > -->
           默认
          <!-- </el-tooltip> -->
          </div>
         {{ scope.row.personnerName  }}
      </template>
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- 启用禁用 -->
      <template slot="enableStatus" slot-scope="{ row }" >
        <el-switch
          v-if="role === '20'&&showEnableStatus(row)"
          v-model="row.enableStatus"
          :active-color="'#6996FF'"
          :inactive-color="'#dcdfe6'"
          active-value="1"
          inactive-value="0"
          @change="handleLeaderChange(row)"
        >
          <div>==</div>
        </el-switch>
        <div v-else-if="role === '22'&&showEnableStatus(row)">{{ row.enableStatus === "0" ? "禁用" : "启用" }}</div>
        <div v-else>{{'-' }}</div>
      </template>
      <template slot="action" slot-scope="scope">
        <div class="action">
          <icon-button
            @click.prevent="detailsInfo(scope.row)"
            content="查看"
            icon="iconfont iconchakan"
          ></icon-button>

          <!-- 平台端.只有企业认证,待认证才有该按钮 -->
          <icon-button
            v-if="role === '20'&&scope.row.authFileStatus!=='1'&&scope.row.sealAdminType==='1'"
            @click.prevent="showUpdate(scope.row.keyId)"
            content="上传授权书"
            icon="iconfont iconshangchuan"
          ></icon-button>
          <!-- 变更编辑二选一   2已认证》变更    1认证中》编辑      没做暂时注释-->
          <!-- <icon-button
            v-if="role === '22' && scope.row.authStatus === '2'"
            @click.prevent="changeInfo(scope.row)"
            content="变更"
            icon="iconfont iconbiangeng"
          ></icon-button> -->
          <icon-button
            v-if="role === '22' &&scope.row.authStatus === '1' &&scope.row.sealAdminType === '2'"
            @click.prevent="editInfo(scope.row)"
            content="编辑"
            icon="iconfont iconbianji3"
          ></icon-button>
          <icon-button
            v-if="role === '22'"
            @click.prevent="deleteInfo(scope.row.keyId)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></icon-button>
        </div>
      </template>
    </base-table>
    <!-- 详情弹出框  -->
    <certificate-details
      :visible.sync="visibleDetail"
      :keyId="keyId"
      :dropOptions="dropOptions"
      :detailsInfoData="detailsInfoData"
    ></certificate-details>
    <!-- 上传授权书 -->
    <update-flies-dialog :visible.sync="updateVisible" :keyId="keyId">
    </update-flies-dialog>
    <!-- 选择签章人弹出框 -->
    <select-signer-dialog
      :visible.sync="selectVisible"
      :enterpriseId="enterpriseId"
    ></select-signer-dialog>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseForm from '@/components/common/base-form/base-form'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { fromConfig, columnsConfig } from './utils/config.js'
import { certifyApi, supplierApi } from '@/api/companyApi'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import CertificateDetails from './components/certificate-detalis.vue'
import UpdateFliesDialog from './components/upload-files-dialog.vue'
import SelectSignerDialog from './components/select-signer-dialog.vue'
import { getDictLists } from '@/filters/fromDict'
import { filterData } from './utils/utils'
import * as UserApi from '@/api/sys/user'
import { downFile } from '@/utils/devUtils'
export default {
  name: 'supplierManagement',
  components: {
    BaseForm,
    BaseTable,
    IconButton,
    BaseButton,
    CertificateDetails,
    UpdateFliesDialog,
    SelectSignerDialog
  },
  data () {
    return {
      queryParas: {}, // 查询内容
      tableData: [],
      fold: false, // 查询显示隐藏
      visibleDetail: false, // 查看详情弹窗
      updateVisible: false, // 上传授权书弹窗
      selectVisible: false, // 选择签章人弹窗
      keyId: '', // 签章人keyId
      dropOptions: [], // 供应商选项
      show: true, // 模拟两种角色下隐藏显示
      detailsInfoData: {}, // 详情
      role: '', // 角色信息,
      orgAnizationId: '', // 企业id查询条件
      enterpriseId: '', // 企业id
      enterpriseQuery: {}, // 企业信息,
      authStatus: '', // 本地认证状态
      remoteAuthStatus: '', // 远端认证状态
      queryType: '', // 角色类型
      showBtn: '新增企业认证'// 企业认证按钮字段
    }
  },
  created () {
    // 获取角色信息
    this.role = this.$store.state.user.userInfo.userType
    // id
    this.orgAnizationId = this.$store.state.user.userInfo.organizationId
  },
  mounted () {
    this.gysList()
    this.handleFilter()
    this.getGysInfo() // 查企业信息，拿到enterpriseId
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    form () {
      return fromConfig(this)
    },
    // 申请类型
    sealAdminOptions () {
      return getDictLists('APPLY_TYPE')
    },
    // 认证状态
    authenticationOptions () {
      return getDictLists('AUTH_STATUS')
    },
    // 授权书状态
    authFileOptions () {
      return getDictLists('SIGN_FILE_STATUS')
    },
    // 禁用状态
    enableOptions () {
      return getDictLists('ENABLE_STATUS')
    }
  },
  watch: {
    enterpriseId (val) {
      if (val) {
        this.getEnterpriseStutas(val)
      }
    },
    // 企业认证按钮显示类型
    authStatus (val) {
      const lableList = ['新增企业认证', '继续企业认证', '完成企业认证']
      this.showBtn = lableList[parseInt(val)]
    }
  },
  methods: {
    // TODO  模板CODE 替换
    handleBusiness () {
      UserApi.downloadUserTemplate({
        templateCode: 'AXQ_BUSINESS_FILE_CODE'
      })
        .then((res) => {
          downFile(res)
        })
        .catch(() => {})
    },
    // 显示启用禁用状态
    showEnableStatus (data) {
      if (data.sealAdminType === '1') {
        return !(data.authFileStatus === '0' || data.authStatus === '1')
      } else {
        return !(data.authStatus === '1')
      }
    },
    //  查询表格数据
    handleFilter () {
      if (this.role === '20') {
        certifyApi.platformTableeData(this.queryParas).then(res => {
          this.tableData = filterData(res.data, 'enterpriseId', 'enterpriseIdRowSpan')
        })
      } else if (this.role === '22') {
        certifyApi.supplierTableeData(this.queryParas).then(res => {
          this.tableData = filterData(res.data, 'enterpriseId', 'enterpriseIdRowSpan')
        })
      }
    },
    // 重置
    clearParams () {
      this.queryParas = {}
    },
    // 查询本地认证状态 0 未认证 1 认证中  2 认证成功
    getEnterpriseStutas (val) {
      certifyApi.getEnterpriseStutas({ enterpriseId: val }).then((res) => {
        if (res.data.companyInfoVO.authStatus) this.authStatus = res.data.companyInfoVO.authStatus
      })
    },
    // 查询远端认证状态  (0:未认证 / 1:认证中 / 2:认证成功 / 3:认证失败)
    async   checkRemoteOrgCertifyStatus () {
      try {
        const res = await certifyApi.checkRemoteOrgCertifyStatus({ enterpriseId: this.enterpriseId })
        if (res.data.status) this.remoteAuthStatus = res.data.status
      } catch (err) {
        console.log(err)
      }
    },
    // 获取企业信息  得到enterpriseId
    getGysInfo () {
      if (this.orgAnizationId) {
        supplierApi.getEnterpriseInfo({ orgAnizationId: this.orgAnizationId }).then((res) => {
          if (res.data.keyId) this.enterpriseId = res.data.keyId
        })
      }
    },
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      if (column.property === 'enterpriseId') {
        const _row = row.enterpriseIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'sealAdminType' || column.property === 'authStatus') {
        const _row = row.sealAdminTypeRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 新增企业认证(供应商)
    async addEnterprise () {
      await this.checkRemoteOrgCertifyStatus()
      if (this.authStatus === '0') { // 本地等于0 未认证
        if (this.remoteAuthStatus === '0') {
          this.$router.push({
            path: '/basicDataManagement/enterpriseCertification'
          })
        } else if (this.remoteAuthStatus === '1') {
          this.warning('企业证书已在保理平台中申请，请申请通过后再试！')
        } else if (this.remoteAuthStatus === '2') {
          this.warning('该企业已在保理平台做过认证，无需重复认证，本次仅添加签章人')
          this.$router.push({
            path: '/basicDataManagement/enterpriseCertification',
            query: { changeState: 'YDRZ' }
          })
        }
      } else if (this.authStatus === '1') { // 本地等于1 认证中
        if (this.remoteAuthStatus === '1') {
          this.$router.push({
            path: '/basicDataManagement/enterpriseCertification'
          })
        } else if (this.remoteAuthStatus === '2') {
          this.warning('该企业已在保理平台做过认证，无需重复认证，本次仅添加签章人')
          this.$router.push({
            path: '/basicDataManagement/enterpriseCertification',
            query: { changeState: 'YDRZ' }
          })
        }
      }
    },
    // 新增个人认证（供应商）
    addPersonal () {
      this.$router.push({
        path: '/basicDataManagement/personalCertification',
        query: { changeState: '0' }
      })
    },
    // 查看详情
    detailsInfo (data) {
      this.detailsInfoData = data
      this.visibleDetail = true
    },
    // 上传授权书
    showUpdate (keyId) {
      this.keyId = keyId
      this.updateVisible = true
    },
    // 变更（供应商）
    changeInfo (data) {
      if (data.sealAdminType === '1') {
        this.$router.push({
          path: '/basicDataManagement/enterpriseCertification',
          query: { changeState: 'BGGYS', sealAdminId: data.keyId } // 变更供应商
        })
      } else if (data.sealAdminType === '2') {
        this.$router.push({
          path: '/basicDataManagement/personalCertification',
          query: { changeState: 'BGGR', keyId: data.keyId } // 变更个人
        })
      }
    },
    // 获取个人认证状态

    // 编辑（供应商）
    async  editInfo (data) {
      if (data.sealAdminType === '1') { // 企业编辑
        this.$router.push({
          path: '/basicDataManagement/enterpriseCertification',
          query: { changeState: 'BJGYS' } // 编辑供应商
        })
      } else if (data.sealAdminType === '2') { // 个人编辑
      // 判断当前签章人是否还存在
        try {
          const res = await certifyApi.bizStaus({ sealAdminId: data.keyId })
          if (!res.data.personnelInfoVO && !res.data.personnelInfoVO?.keyId && !res.data.personnelInfoVO?.idCard) {
            this.warning('当前证书状态异常，请刷新页面后重试')
            return
          } else {
          }
        } catch (err) {
          console.log(err)
        }
        this.$router.push({
          path: '/basicDataManagement/personalCertification',
          query: { changeState: 'BJGR', keyId: data.keyId } // 编辑个人
        })
      }
    },
    // 删除（供应商）
    deleteInfo (keyId) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除吗？',
        show: true,
        type: 'warning'
      })
        .then(() => {
          certifyApi.delSealAdmin({ sealAdminId: keyId }).then((res) => {
            if (res.data) {
              this.handleFilter()
              this.success('删除成功')
            }
          })
        })
        .catch(() => {})
    },
    // 启用禁用状态(业务经理)
    handleLeaderChange (row) {
      const { enableStatus } = row
      if (enableStatus === '1') {
        // 启用
        this.$stateConfirm({
          title: '提示',
          message: '确认启用吗？',
          show: true,
          type: 'warning'
        })
          .then(() => {
            row.enableStatus = '1'
            const newData = {
              keyId: row.keyId,
              enableStatus: enableStatus * 1
            }
            certifyApi.changeEnable(newData).then((res) => {
              if (res.data) {
                this.success('已启用')
              }
            })
          })
          .catch(() => {
            row.enableStatus = '0'
          })
      } else if (enableStatus === '0') {
        // 禁用
        this.$stateConfirm({
          title: '提示',
          message: '确认禁用吗？',
          show: true,
          type: 'warning'
        })
          .then(() => {
            row.enableStatus = '0'
            const newData = {
              keyId: row.keyId,
              enableStatus: enableStatus * 1
            }
            certifyApi.changeEnable(newData).then((res) => {
              if (res.data) {
                this.success('已禁用')
              }
            })
          })
          .catch(() => {
            row.enableStatus = '1'
          })
      }
    },
    // 获取申请主体(获取供应商)
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.dropOptions = res.data
        }
      })
    },
    // 设置默认签章人
    // setIsDefault (data) {
    //   // alert(55555555555)
    // },
    // 授权书状态 回显
    changeAuthFileStatus (row) {
      if (row.sealAdminType === '1') {
        const [obj] = this.authFileOptions.filter(item => item.dictId === row.authFileStatus)
        return obj ? obj.dictName : ''
      } else if (row.sealAdminType === '2') {
        return '-'
      }
    }

  }
}
</script>
<style lang="scss" src='./index.scss' scoped/>
