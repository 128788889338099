import BaseSelect from '@/components/common/base-select/base-select'

// 查询
export const fromConfig = (content) => {
  return [
    {
      label: '申请企业', // ?????
      prop: 'enterpriseId',
      attrs: {
        placeholder: '请选择',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true,
        maxlength: 15
      },
      tag: BaseSelect,
      maxlength: 15,
      isHidden: content.role === '22' // 在供应商时隐藏
    },
    {
      label: '申请类型', // ?????
      prop: 'sealAdminType',
      attrs: {
        placeholder: '请选择',
        options: content.sealAdminOptions,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      tag: BaseSelect
    },
    {
      label: '姓名',
      prop: 'name',
      attrs: {
        placeholder: '请输入',
        maxlength: '20'
      }
    },
    {
      label: '手机号',
      prop: 'phone',
      attrs: {
        placeholder: '请输入',
        maxlength: '11'
      }
    },
    {
      label: '邮箱',
      prop: 'email',
      attrs: {
        placeholder: '请输入',
        maxlength: '30'
      },
      isHidden: content.fold

    },
    {
      label: '认证状态',
      prop: 'authStatus',
      attrs: {
        placeholder: '请选择',
        options: content.authenticationOptions,
        selectedField: ['dictId', 'dictName']
      },
      isHidden: content.fold,
      tag: BaseSelect
    },
    {
      label: '授权书状态',
      prop: 'authFileStatus',
      attrs: {
        placeholder: '请选择',
        options: content.authFileOptions,
        selectedField: ['dictId', 'dictName']
      },
      isHidden: content.fold,
      tag: BaseSelect
    },
    {
      label: '状态',
      prop: 'enableStatus',
      attrs: {
        placeholder: '请选择',
        options: content.enableOptions,
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      isHidden: content.fold
    }
  ]
}
// 表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '申请企业',
      prop: 'enterpriseId',
      minWidth: '300px',
      'show-overflow-tooltip': true,
      align: 'left',
      isHidden: content.role === '22',
      formatter: row => {
        // const [obj] = content.dropOptions.filter(item => item.keyId === row.enterpriseId)
        return row.enterpriseName
      }
    },
    {
      label: '申请类型',
      prop: 'sealAdminType',
      formatter: row => {
        const [obj] = content.sealAdminOptions.filter(item => item.dictId === row.sealAdminType)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '认证状态',
      prop: 'authStatus', // 认证状态（0：已认证1：认证中）
      formatter: row => {
        const [obj] = content.authenticationOptions.filter(item => item.dictId === row.authStatus)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: content.role === '20' ? '姓名' : '签章人',
      prop: 'personnerName'

    },
    {
      label: '手机号',
      prop: 'mobilePhoneNo',
      minWidth: '150px'
    },
    {
      label: '邮箱',
      prop: 'email',
      minWidth: '150px',
      formatter: row => {
        return row.email ? row.email : '-'
      }
    },
    {
      label: '授权书状态',
      prop: 'authFileStatus', // 授权书状态(0:未上传 / 1:已上传 / 2:已失效)
      'min-width': '120px',
      formatter: row => {
        return content.changeAuthFileStatus(row)
        // const [obj] = content.authFileOptions.filter(item => item.dictId === row.authFileStatus)
        // return obj ? obj.dictName : ''
      }
    },
    {
      label: content.role === '20' ? '禁用/启用' : '状态',
      prop: 'enableStatus',
      'min-width': '100px',
      isHidden: false, // 在供应商时隐藏
      formatter: row => {
        const [obj] = content.enableOptions.filter(item => item.dictId === row.enableStatus)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
