// 合并企业类型
export function filterData (arr = [], innerKey, orderKey) {
  const res = []
  const map = {}
  arr.forEach((item) => {
    const key = item[innerKey]
    item[orderKey] = 0
    if (!map[key]) {
      map[key] = [item]
    } else {
      map[key].push(item)
    }
  })
  for (const key in map) {
    const list = map[key]
    list[0][orderKey] = list.length
    if (innerKey === 'enterpriseId') {
      res.push(
        ...newfilterData(list, 'sealAdminType', 'sealAdminTypeRowSpan')
      )
    } else {
      res.push(...list)
    }
  }
  return res
}
// 合并认证类型 企业合并,个人不合并
function newfilterData (arr = [], innerKey, orderKey) {
  const res = []
  const map = {}
  arr.forEach((item) => {
    const key = item[innerKey]
    item[orderKey] = 0
    if (!map[key]) {
      map[key] = [item]
    } else {
      map[key].push(item)
    }
  })
  for (const key in map) {
    const list = map[key]
    if (innerKey === 'sealAdminType') {
      if (list[0][innerKey] === '1') {
        list[0][orderKey] = list.length
      } else {
        list.forEach((item) => {
          item[orderKey] = 1
        })
      }
      res.push(...list)
    }
  }
  return res
}
